/* purgecss start ignore */
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;
/* purgecss end ignore */

body {
  font-family: 'Inter', sans-serif;
}

.prose p img {
  @apply mx-auto;
  @apply block;
}

.prose h3 {
  @apply text-pink-graphql;
}

.prose code {
  @apply bg-gray-200 p-1 rounded;
}

.prose a {
  @apply text-blue-600 font-medium no-underline;
}

.prose a:hover {
  @apply underline;
}

.graphiql-container .topBar {
  @apply py-4;
}

.graphiql-container .custom-toolbar-button {
  @apply text-white py-1 px-2 my-1 mx-2 text-sm rounded;
}

.graphiql-container .doc-explorer-title {
  padding: 0 !important;
}

.graphiql-container .title {
  @apply hidden;
}

.graphiql-container .execute-button-wrap {
  margin: 0 !important;
}

/* @media screen(sm) {
  .graphiql-container .title {
    @apply visible;
  }

  .graphiql-container .execute-button-wrap {
    @apply m-4;
  }
} */

.prose blockquote p:first-of-type::before {
  content: none;
}

/* Reset Code*/
.prose code::before {
  content: '' !important;
}
.prose code::after {
  content: '' !important;
}

.token.description {
  color: slategray !important;
}

/* Maybe should be only on pages not slides */
/* blockquote {
  font-size: 120%;
  font-weight: bold;
  text-align: left;
}

blockquote div::before {
  content: '\\201C';
}

blockquote div::after {
  content: '\\201D';
}

cite {
  font-size: 80%;
  font-weight: normal;
  font-style: normal;

  display: block;

  margin-top: 2rem;
} */
